import React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import PropTypes from 'prop-types'
import { ParallaxProvider } from 'react-scroll-parallax'

import resetCss from './reset'
import fonts from './fonts'
import theme from './theme'

const Global = createGlobalStyle`
  ${resetCss}
  ${fonts}

  html {
    font-family: 'Avenir Next', sans-serif;
  }
  *, *:before, *:after {
    box-sizing: border-box;
    line-height: 1.33;
  }

  strong, b {
    font-weight: 500;
  }
`

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Global />
    <ParallaxProvider>{children}</ParallaxProvider>
  </ThemeProvider>
)

Layout.propTypes = { children: PropTypes.node.isRequired }

export default Layout
