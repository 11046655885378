const theme = {
  padding: '12px',
  form: {
    inputColor: '#777',
    ctaColor: '#FF8B45',
    ctaColorDarken: '#D96C41',
  },
  logo: {
    fontSize: '3vw',
  },
  title: {
    fontSize: '6vw',
  },
  colors: {
    red: '#cd1719',
    bgGrey: '#f0f0f0',
    textGrey: '#333',
  },
  breakpoints: {
    desktop: '1080px',
  },
}

export default theme
