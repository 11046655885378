import AvenirNextWoff2 from '@static/fonts/AvenirNext-Regular.woff2'
import AvenirNextWoff from '@static/fonts/AvenirNext-Regular.woff'
import AvenirNextBoldWoff2 from '@static/fonts/AvenirNext-DemiBold.woff2'
import AvenirNextBoldWoff from '@static/fonts/AvenirNext-DemiBold.woff'
import AvenirNextHeavyWoff2 from '@static/fonts/AvenirNext-Heavy.woff2'
import AvenirNextHeavyWoff from '@static/fonts/AvenirNext-Heavy.woff'
import ProximaNovaWoff2 from '@static/fonts/ProximaNova-Regular.woff2'
import ProximaNovaWoff from '@static/fonts/ProximaNova-Regular.woff'
import ProximaNovaBoldWoff2 from '@static/fonts/ProximaNova-Semibold.woff2'
import ProximaNovaBoldWoff from '@static/fonts/ProximaNova-Semibold.woff'
import BodoniWoff from '@static/fonts/BodoniXT-webfont.woff'

const fontFaces = `

@font-face {
  font-family: 'Bodoni';
  src:
  url(${BodoniWoff}) format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src:
  url(${AvenirNextWoff2}) format('woff2'),
  url(${AvenirNextWoff}) format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src:
  url(${AvenirNextBoldWoff2}) format('woff2'),
  url(${AvenirNextBoldWoff}) format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Avenir Next';
  src:
  url(${AvenirNextHeavyWoff2}) format('woff2'),
  url(${AvenirNextHeavyWoff}) format('woff');
  font-weight: 900;
}

@font-face {
  font-family: 'Jack';
  src:
  url(${ProximaNovaWoff2}) format('woff2'),
  url(${ProximaNovaWoff}) format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'Jack';
  src:
  url(${ProximaNovaBoldWoff2}) format('woff2'),
  url(${ProximaNovaBoldWoff}) format('woff');
  font-weight: bold;
}
`

export default fontFaces
